<template>
  <div class="materialConversion">
    <PageHeader title="物料转换" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20 SearchBox_30">
      <div class="flex" style="flex: 1">
        <el-input
          v-model="searchForm.KeyWords"
          size="medium"
          placeholder="可通过公司物料编号、供应商物料编号、供应商搜索"
          class="rule-input-edit SearchCircle"
        >
          <template slot="prepend">
            <i class="el-icon-search" />
          </template>
          <div
            slot="append"
            class="cursor select_none"
            @click="search"
          >
            搜 索
          </div>
        </el-input>
        <div v-if="$minCommon.checkMeau('wlzhadd', userBottons)" class="out p-left-10 flex a-center">
          <el-button
            class="ModelBttton-white-30"
            round
            type="primary"
            icon="el-icon-s-fold"
            @click="creatArdess"
          >新增物料转换设置</el-button>
        </div>
      </div>
    </div>
    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel4"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 330px)"
        row-key="id"
        border
      >
        <el-table-column prop="FromInvCode" label="本公司物料编号" align="center" show-overflow-tooltip />
        <el-table-column prop="ToInvCode" label="供应商物料编号" align="center" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" align="center" show-overflow-tooltip />
        <el-table-column prop="SellCompanyName" label="供应商" align="center" show-overflow-tooltip />
        <el-table-column prop="UserName" label="操作人" align="center" show-overflow-tooltip />
        <el-table-column prop="UpdateTime" label="最后更新时间" align="center" show-overflow-tooltip />
        <el-table-column prop="set" align="center" label="操作" width="50">
          <!--  slot-scope="scope" -->
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('wlzhedit', userBottons)" class="OperationButton" @click="edit(scope.row)">
                  <el-link :underline="false"><i class="el-icon-edit p-right-5" />编辑</el-link>
                </div>
                <div v-if="$minCommon.checkMeau('wlzhdelete', userBottons)" class="OperationButton" @click="delAdress(scope.row.IDX)">
                  <el-link :underline="false"><i class="el-icon-delete p-right-5" />删除</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 新建物料转换 start -->
    <el-dialog class="DepartmentBox" width="400px!important" :visible="showSetAddress" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">物料转换</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="cancel" />
      </div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="125px" class="demo-ruleForm p-top-20">
        <el-form-item label="供应商名称" prop="SellCompanyID">
          <!-- <el-input v-model="ruleForm.SellCompanyName" size="mini" /> -->
          <el-select v-model="ruleForm.SellCompanyID" style="width:100%" remote :loading="loading" :remote-method="remoteMethod" filterable size="mini" placeholder="请选择">
            <el-option
              v-for="(item, index) in clients"
              :key="index"
              :label="item.text"
              :value="item.IDX"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="本公司物料编号" prop="FromInvCode">
          <el-input v-model="ruleForm.FromInvCode" size="mini" />
        </el-form-item>
        <el-form-item label="供应商物料编号" prop="ToInvCode">
          <el-input v-model="ruleForm.ToInvCode" size="mini" />
        </el-form-item>
        <div class="caozuo p-tb-20 t_line_s m-top-20">
          <el-button @click="cancel">取消</el-button>
          <el-button :loading="loading" type="primary" @click="submitAddress('ruleForm')">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 新建物料转换 end-->
  </div>
</template>

<script>
import Index from '@/minxin/purchasing-management/materialConversion'

export default Index
</script>

