import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'

const Index = {
  name: 'MaterialConversion',
  components: {
    PageHeader
  },
  data() {
    return {
      searchForm: {
        KeyWords: '',
        PageIndex: 1,
        PageSize: 10
      },
      loading: false,
      root_name: 'wlzh',
      root_msg: '物料转换',
      showSetAddress: false,
      ruleForm: {
        IDX: '',
        SellCompanyID: '',
        FromInvCode: '',
        ToInvCode: ''
      },
      ruleForm_temp: {
        IDX: '',
        SellCompanyID: '',
        FromInvCode: '',
        ToInvCode: ''
      },
      clients: [],
      rules: {
        SellCompanyID: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' }
        ],
        FromInvCode: [
          { required: true, message: '请输入本公司物料编号', trigger: 'blur' }
        ],
        ToInvCode: [
          { required: true, message: '请输入供应商物料编号', trigger: 'blur' }
        ]
      }

    }
  },
  methods: {
    search() {
      this.getTableDataAsync()
    },
    creatArdess() {
      this.showSetAddress = true
      this.ruleForm.IDX = ''
      this.GetCompanyNameQueryListJson()
      this.$refs['ruleForm'].resetFields()
      this.ruleForm = this.ruleForm_temp
    },
    remoteMethod(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.GetCompanyNameQueryListJson(query)
        }, 200)
      } else {
        this.GetCompanyNameQueryListJson()
      }
    },
    cancel() {
      this.showSetAddress = false
      this.ruleForm.IDX = ''
      this.$refs['ruleForm'].resetFields()
      this.ruleForm = this.ruleForm_temp
    },
    // 查询供应商
    async GetCompanyNameQueryListJson(CompanyName) {
      const result = await this.$api.GetCompanyNameQueryListJson({
        'CompanyType': 1,
        'CompanyName': CompanyName || '',
        'pageIndex': 1,
        'pageSize': 50
      })
      console.log(result)
      this.clients = result.Message
    },
    edit(row) {
      this.GetCompanyNameQueryListJson()
      this.showSetAddress = true
      this.ruleForm.IDX = row.IDX
      for (const key in this.ruleForm) {
        this.ruleForm[key] = row[key]
      }
    },
    delAdress(IDX) {
      this.$api.DeletePurchaseConvertInvCode({
        IDX
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误:' + res.RetMsg)
        this.$message.success('删除成功')
        this.refreshCurrentChange()
      }).catch(err => {
        return this.$message.error('错误:' + err)
      })
    },
    submitAddress(formName) {
      this.$refs[formName].validate((valid) => {
        // this.$refs[formName].resetFields()
        if (!valid) return false
        console.log(this.ruleForm)
        this.$api.SavePurchaseConvertInvCode(this.ruleForm).then(res => {
          if (res.RetCode !== '0') return this.$message.error('错误:' + res.RetMsg)
          this.showSetAddress = false
          this.refreshCurrentChange()
          this.ruleForm.IDX = ''
          this.$refs[formName].resetFields()
          this.$message.success('保存成功')
        })
      })
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      this.searchForm.PageIndex = page || 1
      this.searchForm.PageSize = this.tablePageSize
      const response = await this.$api.QueryPurchaseConvertInvCodeList(this.searchForm)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log(response)
      return response
    }
  },
  mixins: [tableMixin, root]
}

export default Index
